import React, { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";
import ProjectDetailsModal from "./ProjectDetailsModal";
import {useTranslation} from "react-i18next";
const Portfolio = ({ classicHeader, darkTheme }) => {
  // init one ref to store the future isotope object
  const isotope = useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState("*");
  const [imagesLoaded, setimagesLoaded] = useState(0);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();
  const { t } = useTranslation();

  const filters = {
    WEB: "Web",
    PYTHON: "Python",
    GAMES: t("portfolio.games"),
  };

  const projectsData = [
    {
      title: "SurveyScape",
      projectInfo:
        "SurveyScape est une plateforme de création de formulaires en ligne. Vos formulaires peuvent être composés de multiples types de questions et sont personnalisables (fonds, logo...) Vous pouvez choisir de partager votre formulaire à vos amis qui sont inscrits sur le site, en envoyant un lien géneré ou le laisser public, donnant la possibilité à tout le monde de répondre à votre formulaire. Il est également possible de mettre en place une date d'échéance à votre formulaire, après laquelle les réponses ne sont plus acceptés. Enfin, vous pourrez suivre avec des graphiques l'évolution en temps réel des réponses reçus sur chacuns des formulaires que vous avez créés.",
      technologies: "PHP Laravel",
      date: "2020",
      socialLinks: {
        github: "https://github.com/Sharkerz/SurveyScape-website",
      },
      thumbImage: "images/projects/surveyscape/surveyscape.png",
      sliderImages: [
        "images/projects/surveyscape/survey_ex2.png",
        "images/projects/surveyscape/survey_ex1.png"
      ],
      categories: [filters.WEB],
    },
    {
      title: "Todolist partagées",
      projectInfo:
        "Plateforme Web permettant de créer des Todolist pour soi ou à partager avec vos amis. Les todolists partagées peuvent être modifiées par tous ceux y ayant accès instantanément.\n" +
          "\n" +
          "Fonctionnalités du service:\n" +
          " Système d'amis,\n" +
          " notifications,\n" +
          " Todolist personnelles,\n" +
          " Todolist partagées avec système de permission,\n" +
          " Thème clair / sombre",
      technologies: "PHP Laravel",
      date: "2019",
      socialLinks: {
        github: "https://github.com/Sharkerz/ToDo",
      },
      thumbImage: "images/projects/ToDoList/todolist.png",
      sliderImages: [
        "images/projects/ToDoList/todo_ex1.png",
        "images/projects/ToDoList/todo_ex2.png",
      ],
      categories: [filters.WEB],
    },
    {
      title: "The good fork",
      projectInfo:
          "Application mobile et service web pour la gestion. L'application permet aux clients de commander , les serveurs et cuisinier ont un accès différent selon leur role et ont pour charge de valider les commandes. L'application est très complète, fidélise les clients et prend en charge les notifications pour le suivi des commandes.",
      technologies: "PHP Laravel, React Native",
      date: "2021",
      socialLinks: {
        github: "https://github.com/Sharkerz/TheGoodFork",
      },
      thumbImage: "images/projects/thegoodfork/img.png",
      categories: [filters.WEB],
    },
    {
      title: "Player - Lecteur de musique type spotify",
      projectInfo:"Player est une plateforme web de partage de musique. Chaque musique ajouté sur la plateforme par un utilisateur pourra être écouté par tous et utilisé dans des playlists.",
      technologies: "ASP.NET, Javascript",
      date: "2020",
      socialLinks: {
        github: "https://github.com/Sharkerz/Music-Player",
      },
      thumbImage: "images/projects/player/player-logo.png",
      categories: [filters.WEB],
    },
    {
      title: "Star Wars shooter",
      projectInfo:
        "Sauvez la galaxie à bord d'un X-Wing en venant à bout de tie fighter de l'empire. Adaptation du jeu Starfox 64. Le jeu pèse 203Mo et nécessite un pc adapté aux jeux 3D",
      technologies: "Unity, C#",
      date: "2020",
      url: {
        name: "Télechargement",
        link: "files/StarWarsShooter.zip",
      },
      socialLinks: {
        github: "https://github.com/Sharkerz/StarShooter",
      },
      thumbImage: "images/projects/starwarsshooter/starwars.png",
      sliderImages: [
        "images/projects/starwarsshooter/starshooter_ex1.png",
      ],
      categories: [filters.GAMES],
    },
    {
      title: "Bot discord jail",
      projectInfo:
          "Bot discord à installer sur votre serveur. Il vous permettra d'envoyer en prison un utilisateur pendant un temps défini lorsque celui-ci est connecté à un canal vocal.",
      technologies: "Python",
      date: "2020",
      url: {
        name: "Installer le bot sur mon serveur",
        link: "https://discord.com/api/oauth2/authorize?client_id=781095685180358666&permissions=8&scope=bot",
      },
      socialLinks: {
        github: "https://github.com/Sharkerz/JailDiscordBot",
      },
      thumbImage: "images/projects/jailbotdiscord/discord.png",
      categories: [filters.PYTHON],
    },
    {
      title: "Duck simulator",
      projectInfo:
          "Simulateur de canard en 3D développé avec le moteur graphique JMonkeyEngine. Les canards mangent des nenuphars pour grandir, les plus gros seront dominants (et donc bruyants).",
      technologies: "Java",
      date: "2018",
      socialLinks: {
        github: "https://github.com/Sharkerz/Duck-simulator",
      },
      thumbImage: "images/projects/duckSimulator/duck.jpg",
      sliderImages: [
        "images/projects/duckSimulator/ducksimulator_ex1.png",
      ],
      categories: [],
    },
    {
      title: "Mr.Driller",
      projectInfo:
          "Vous incarnez bien évidemment, le célèbre mafieux Al Capone, retenu dans la prison d’Alcatraz. Après avoir tenté de soudoyer le gardien, vous êtes placé en isolement. Jusqu’à présent, votre plan se déroule à merveille car vous êtes maintenant libre de préparer une tentative d’évasion ! Muni, d’une simple cuillère, vous décidez de creuser un tunnel pour aller reconquérir votre empire aux mains de vos ennemis. Al Capone est connu pour être dénué de sentiments et n’a comme obsessions que l’argent et le pouvoir. Il n’hésite pas à tuer et sacrifier des milliers de personnes pour son intérêt personnel. Il est devenu l’archétype de l’anti-héros et du gangster. Tachez de lui ressembler un maximum si vous voulez réussir votre évasion, ne faites paraître aucun état-d‘âmes. Bonne chance, vous en aurez besoin...",
      technologies: "Python, librairie pygame",
      date: "2018",
      socialLinks: {
        github: "https://github.com/Sharkerz/Mr.Driller",
      },
      thumbImage: "images/projects/Driller/MrDriller.PNG",
      sliderImages: [
        "images/projects/Driller/mrdiller_ex1.PNG",
        "images/projects/Driller/mrdiller_ex2.PNG",
      ],
      categories: [filters.GAMES, filters.PYTHON],
    }
  ];

  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope(".portfolio-filter", {
      itemSelector: ".filter-item",
      layoutMode: "masonry",
    });

    // cleanup
    return () => {
      isotope.current.destroy();
    };
  }, []);

  // handling filter key change
  useEffect(() => {
    if (imagesLoaded) {
      filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, imagesLoaded]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  return (
    <>
      <section
        id="portfolio"
        className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
      >
        <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
          {/* Heading */}
          <div className="position-relative d-flex text-center mb-5">
            <h2
              className={
                "text-24  text-uppercase fw-600 w-100 mb-0 " +
                (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
              }
            >
              Portfolio
            </h2>
            <p
              className={
                "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
                (darkTheme ? "text-white" : "text-dark")
              }
            >
              {" "}
              {t("portfolio.my_work")}
              <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
            </p>
          </div>
          {/* Heading end*/}
          {/* Filter Menu */}
          <ul
            className={
              "portfolio-menu nav nav-tabs justify-content-center border-bottom-0 mb-5 " +
              (darkTheme ? "nav-light" : "")
            }
          >
            <li className="nav-item">
              <button
                className={"nav-link " + (filterKey === "*" ? "active" : "")}
                onClick={handleFilterKeyChange("*")}
              >
                All
              </button>
            </li>
            {Object.keys(filters).map((oneKey, i) => (
              <li className="nav-item" key={i}>
                <button
                  className={
                    "nav-link " +
                    (filterKey === filters[oneKey] ? "active" : "")
                  }
                  onClick={handleFilterKeyChange(filters[oneKey])}
                >
                  {filters[oneKey]}
                </button>
              </li>
            ))}
          </ul>
          {/* Filter Menu end */}
          <div className="portfolio popup-ajax-gallery">
            <div className="row portfolio-filter filter-container g-4">
              {projectsData.length > 0 &&
                projectsData.map((project, index) => (
                  <div
                    className={
                      "col-sm-6 col-lg-4 filter-item " +
                      project.categories.join(" ")
                    }
                    key={index}
                  >
                    <div className="portfolio-box rounded">
                      <div className="portfolio-img rounded">
                        <img
                          onLoad={() => {
                            setimagesLoaded(imagesLoaded + 1);
                          }}
                          className="img-fluid d-block portfolio-image"
                          src={project.thumbImage}
                          alt=""
                        />
                        <div className="portfolio-overlay">
                          <a
                            className="popup-ajax stretched-link"
                            href=""
                            onClick={() => {
                              setSelectedProjectDetails(projectsData[index]);
                            }}
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          />
                          <div className="portfolio-overlay-details">
                            <h5 className="text-white fw-400">
                              {project.title}
                            </h5>
                            <span className="text-light">Category</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      <div className="project-details-modal">
        {/* Modal */}
        <ProjectDetailsModal
          projectDetails={selectedProjectDetails}
          darkTheme={darkTheme}
        ></ProjectDetailsModal>
      </div>
    </>
  );
};

export default Portfolio;
