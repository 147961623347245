import React from "react";
import resumeFile from "../documents/resume.pdf";
import {useTranslation} from "react-i18next";

const Resume = ({ classicHeader, darkTheme }) => {
  const { t } = useTranslation();

  const educationDetails = [
    {
      yearRange: "2018 - 2023",
      title: "Expert en informatique et systèmes d'information",
      place: "Supinfo - Strasbourg",
      desc: "",
    },
    {
      yearRange: "2015 - 2018",
      title: "Bac ES mathématiques avec mention",
      place: "Lycée Fustel de Coulanges - Strasbourg",
      desc: "Mention anglais européenne",
    }
  ];

  const experienceDetails = [
    {
      yearRange: "Aujourd'hui (soir et week-end)",
      title: "Développeur freelance",
      place: "",
      desc: "Développement de modules PHP (prestashop, wordpress woocommerce)",
    },
    {
      yearRange: "2021 - Aujourd'hui",
      title: "Apprenti Analyste développeur",
      place: "Euro Information",
      desc: "Développement ASP.NET et COBOL",
    },
    {
      yearRange: "2020 - 2021",
      title: "Alternance direction du numérique",
      place: "Université de Strasbourg",
      desc: "Développement d'une application web Django / PostgreSQL\n" +
          "Développement VueJs",
    },
    {
      yearRange: "2020 - 2021",
      title: "Hotliner et développeur",
      place: "LDE",
      desc: "Utilisation de l'ERP 4D Dimension, Gestion de tickets,"
    },
    {
      yearRange: "2019",
      title: "Stage en informatique",
      place: "Arte",
      desc: "Mise en place d'un mitmproxy, développement web/php\n" +
          "Mise en place d'un bot Slack",
    },
  ];

  const skills = [
    {
      name: "PHP Laravel",
      percent: 85,
    },
    {
      name: "Django",
      percent: 80,
    },
    {
      name: "Python",
      percent: 80,
    },
    {
      name: "ASP.NET",
      percent: 80,
    },
    {
      name: "Systèmes linux",
      percent: 80,
    },
    {
      name: "Unity Engine C#",
      percent: 70,
    },
  ];

  return (
    <section
      id="resume"
      className={"section " + (darkTheme ? "bg-dark-1" : "")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            {t("resume.summary")}
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            {t("resume.resume")}
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gx-5">
          {/* My Education */}
          <div className="col-md-6">
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              {t("resume.my_edu")}
            </h2>
            {educationDetails.length > 0 &&
              educationDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    "bg-white  rounded p-4 mb-4 " +
                    (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                  <p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                    {value.title}
                  </h3>
                  <p className={darkTheme ? "text-primary" : "text-danger"}>
                    {value.place}
                  </p>
                  <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                    {value.desc}
                  </p>
                </div>
              ))}
          </div>
          {/* My Experience */}
          <div className="col-md-6">
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              {t("resume.my_xp")}
            </h2>
            {experienceDetails.length > 0 &&
              experienceDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    "bg-white  rounded p-4 mb-4 " +
                    (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                  <p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                    {value.title}
                  </h3>
                  <p className={darkTheme ? "text-primary" : "text-danger"}>
                    {value.place}
                  </p>
                  <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                    {value.desc}
                  </p>
                </div>
              ))}
          </div>
        </div>
        {/* My Skills */}
        <h2
          className={
            "text-6 fw-600 mt-4 mb-4 " + (darkTheme ? "text-white" : "")
          }
        >
          {t("resume.my_skills")}
        </h2>
        <div className="row gx-5">
          {skills.length > 0 &&
            skills.map((skill, index) => (
              <div key={index} className="col-md-6">
                <p
                  className={
                    " fw-500 text-start mb-2 " +
                    (darkTheme ? "text-light" : "text-dark")
                  }
                >
                  {skill.name}{" "}
                  <span className="float-end">{skill.percent}%</span>
                </p>
                <div
                  className={
                    "progress progress-sm mb-4 " + (darkTheme ? "bg-dark" : "")
                  }
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: skill.percent + "%" }}
                    aria-valuenow={skill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            ))}
        </div>
        <div className="text-center mt-5">
          <a
            className="btn btn-outline-secondary rounded-pill shadow-none"
            href={resumeFile}
            download
          >
            {t("resume.download_cv_button")}
            <span className="ms-1">
              <i className="fas fa-download" />
            </span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Resume;
